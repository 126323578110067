<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
       <div class="top">
         <div class="top_left">当前位置：政策法规&nbsp;→&nbsp;政策详情</div>
         <div class="top_right">
           <div class="button" @click="goPlar()">返回</div>
         </div>
       </div>
       <div class="foot">
         <div class="one">
           <div class="title_">
             <div class="t1">
                {{tableData.title}}
             </div>
             <div class="t2">
               <span>发布人：东乡住建局</span>
               <span>发布时间： {{tableData.createTime}}</span>
             </div>
           </div>
           <div class="cd" v-html="htmlDecodeByRegExp(tableData.content)">
           </div>
         </div>
       </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'
export default {
  name: 'PlarDetail',
  components: { MyFram },
  data () {
    return {
      noitfyId:this.$route.query.noitfyId,
      url:this.$Config.base_server,
      tableData:{}
    }
  },
  mounted() {
    this.$axios.post(this.url+'/QueryzcfgById?id='+this.noitfyId).then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;
      }else{
        this.$message.error('对不起,查询出错了');
      }
    })

  },
  methods: {
    goPlar () {
      this.$router.push({ path: 'plar' })
    },
    htmlDecodeByRegExp (str){
      var temp = "";
      if(str && str.length == 0) return "";
      temp = str.replace(/&amp;/g,"&");
      temp = temp.replace(/&lt;/g,"<");
      temp = temp.replace(/&gt;/g,">");
      temp = temp.replace(/&nbsp;/g," ");
      temp = temp.replace(/&#39;/g,"\'");
      temp = temp.replace(/&quot;/g,"\"");
      return temp;
    }


  }
}
</script>

<style scoped  lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}
.content{
  height 100%
  width 100%
  display: flex;
  flex-direction: column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }
    .top_right{
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #ffffff
        margin-right 1rem
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    .one{
      width: 100%;
      height: 100%;
      background #DBEEEC
      display flex
      flex-direction column
      border-radius: 1rem;
      border: 1px solid #82D5FF80 ;
      padding 1rem 1rem
      .title_{
        height 16%
        border-bottom 1px solid #00B097
        .t1{
          height 60%
          text-align center
          display flex
          flex-direction row
          justify-content center
          align-items center
          font-weight: 500;
          font-size: 1.5rem;
          color: #333333;
        }
        .t2{
          height 40%
          display flex
          flex-direction row
          justify-content center
          font-size: 1rem;
          color: #999999;
          letter-spacing: 0;
          opacity 0.6
          span{
            margin-right 2rem
          }
        }
      }
      .cd{
        height 84%
        font-size: 1.5rem;
        color: #333333;
        letter-spacing: 0;
        text-align: justify;
        line-height: 2.8rem;
        padding-top 1.6rem
        text-indent 3rem
        text-overflow: ellipsis;
        overflow-y auto
      }
    }
  }
}
</style>
